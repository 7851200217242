import React from 'react'
import { Anchor, Divider } from 'antd'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import BlogPostWrapper from '../../components/BlogPost'
import { ExternalLink, HomeLink, InternalLink } from '../../components/BlogPost/Texts'
import { Thomas } from '../../components/BlogPost/Authors'
import makeStyles from '@mui/styles/makeStyles'
import SignupBelt from '../../components/Landing/SignupBelt'

const { Link: AnchorLink } = Anchor

const useStyles = makeStyles((theme) => ({
    smallText: {
        color: 'gray',
    },
    italic: {
        fontStyle: 'italic',
    },
}))

const LovenLink = (
    <span style={{ whiteSpace: 'nowrap' }}>
        <InternalLink to="/loven"> LL § 33 A</InternalLink>
    </span>
)

export default function ArticleStartStop({ setCookieValue, data }) {
    const classes = useStyles()
    const anchors = (
        <>
            <AnchorLink href="#titel" title="Intro" />
            <AnchorLink href="#styresignal" title="Styresignal" />
            <AnchorLink href="#ophor" title="Ophør" />
            <AnchorLink href="#losning" title="Planlægning" />
        </>
    )

    return (
        <BlogPostWrapper
            title="Lempelsesperiodens start og ophør"
            setCookieValue={setCookieValue}
            pathName="periode-start-slut"
            anchors={anchors}
        >
            <div
                style={{
                    margin: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // flexWrap: 'wrap',
                    marginBottom: 100,
                }}
                id="titel"
            >
                <Img
                    title="Skattestyrelsen logo"
                    alt="Skattestyrelsen logo"
                    style={{ marginBottom: 100 }}
                    className="landing-guide-illustration-big"
                    fluid={data.s1.childImageSharp.fluid}
                />
                <p className="landing-guide-header-p">
                    <h3>Hvordan er reglerne for start og ophør af en lempelsesperiode?</h3>
                    Det spørgsmål er vi på <HomeLink /> ofte blevet spurgt om, og vi kan godt forstå
                    at man kan blive i tvivl. For der har været meget usikkerhed omkring hvordan
                    6-måneders reglen skal forstås, og hvad der konkret gælder omkring reglen. -
                    Landsskatteretten og Skatterådet har truffet to afgørelser vedrørende
                    6-måneders-reglen, som har givet anledning til fortolkningstvivl.
                    <br />
                    <br />
                    <span id="styresignal">
                        På denne baggrund har SKAT i 2009 udsendt styresignal{' '}
                        <ExternalLink to="https://www.skat.dk/skat.aspx?oid=1842649&lang=da">
                            SKM2009.638.SKAT
                        </ExternalLink>
                        , der efterfølgende er implementeret i Skattestyrelsens juridiske
                        vejledning.
                        <br />
                        Den juridiske vejledning 2021-1, angiver følgende vedrørende en
                        lempelsesperiodes start og ophør:
                    </span>
                    <br />
                    <br />
                    <span className={classes.italic}>
                        "Hvis en lønmodtager i en periode opfylder betingelserne for skattelempelse
                        efter {LovenLink}, vælger lønmodtageren selv, om perioden omfattet af
                        skattelempelse efter LL § 33 A skal starte ved første udrejse eller
                        eventuelt først ved en senere udrejse. Lønindkomsten skal beskattes efter de
                        almindelige regler, indtil lønmodtageren har valgt lempelse efter{' '}
                        {LovenLink}. Se{' '}
                        <ExternalLink to="https://info.skat.dk/data.aspx?oID=1719118">
                            SKM2007.883.LSR
                        </ExternalLink>
                        . Lempelsesperioden starter ved begyndelsen af det udlandsophold, hvor
                        lønmodtageren ønsker lempelse efter {LovenLink}. Det vil sige, at hvis der
                        sker udrejse den 1. juli, kan lempelsesperioden ikke starte fx den 4. juli,
                        hvor der allerede er taget ophold i udlandet.
                        <br />
                        Lempelsesperioden afbrydes først, når betingelserne for reglens anvendelse
                        ikke længere er opfyldt."
                    </span>
                    <br />
                    <br />
                    Her fremgår det relativt klart, at man selv kan vælge hvornår man vil påbegynde
                    sin lempelsesperiode, blot det sker på en udrejse dag fra Danmark.
                    <br />
                    Når det gælder ophør af en lempelsesperiode, kunne beskrivelsen i den juridiske
                    vejledning fremgå tydeligere, men et kig på ordlyden af det oprindelige
                    styresignal udsendt i 2009 vedr. ophør af en lempelsesperiode, giver indsigt:
                    <SignupBelt includeLandingRef />
                    <span className={classes.italic} id="ophor">
                        Lempelsesperiodens ophør
                        <br />
                        <br />
                        <ul className="circled">
                            <li>
                                Lempelsesperioden slutter, når udlandsopholdet afbrydes, det vil
                                sige, når betingelserne for reglens anvendelse ikke længere er til
                                stede - fx hvis ophold her i riget overstiger 42 dage indenfor en 6
                                måneders periode, eller hvis der udføres arbejde her i riget, som
                                ikke er nødvendigt for udlandsopholdet.
                            </li>
                            <li>
                                Skatteyderen kan ikke selv vælge at afslutte et udlandsophold på et
                                tidspunkt, hvor det ikke afbrydes efter loven.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <br />
                    Dette er helt i tråd med et bindende svar fra Skatterådet tilbage i 2008.
                    <ExternalLink to="https://info.skat.dk/data.aspx?oID=1755614">
                        SKM2008.426.SR
                    </ExternalLink>{' '}
                    drejede sig om en skatteyder, der ønskede at placere og fordele
                    lempelsesperioden på vilkårlige tidspunkter under udlandsopholdet. Her fastslår
                    Skatterådet afslutningsvis, at en skatteyder ikke har valgfrihed med hensyn til
                    lempelsesperiodens ophør, da denne afbrydes på det tidspunkt, hvor betingelserne
                    for reglernes anvendelse ikke længere er opfyldt.
                    <br />
                    <br />
                    <span id="losning" />
                    Dette betyder dog ikke, at man som skatteyder ikke selv har indflydelse på
                    hvornår sin lempelsesperiode ophører. Men blot at det kræver planlægning, hvis
                    man selv vil have “hånd i hanke” med datoen for ophør af sine lempelsesperioder.
                    <br />
                    <br />
                    Med planlægning forstås, at man arrangerer sine “42 dages ferie og lign. i DK”
                    således, at man ankommer til DK på den ønskede ophørs dato, hvor de
                    efterfølgende dage i DK opholdet vil overskride 42-dages-reglen.
                    <br />
                    Alternativt vil arbejde udført her i riget som ikke er i direkte forbindelse med
                    udlandsopholdet, også afbryde lempelseperioden.
                    <br />
                    <br />
                    Således vil man kunne arrangere at et udlandsophold afbrydes efter loven på den
                    ønskede ophørsdato. -Og det behøver ikke at være svært…
                    <br />
                    Med compliance værtøjet <HomeLink /> får du god hjælp og overblik over
                    mulighederne omkring dine 42 dage i DK indenfor enhver 6 måneders periode, og du
                    kan derved nemt tilrettelægge start og ophør af dine lempelsesperioder, helt i
                    overensstemmelse med den gældende juridiske vejledning.
                    <br />
                    Dette gøres ved at vælge en slutdato, som er den første dag i den DK-periode,
                    hvor du rammer den 43. dag i DK inden for de seneste 6 måneder.
                    <br />
                    <br />
                    <HomeLink /> vil i en kommende opdatering, tilbyde dig en guide, der viser dine
                    mulige ophørs datoer i et selvvalgt dato interval. Samt angiver tidligste
                    startdato for en efterfølgende lempelsesperiode du måtte vælge.
                </p>
            </div>
            <SignupBelt includeLandingRef />
            <p className={classes.smallText}>Sidst opdateret: 10. august 2021.</p>
            <Divider />
            <p className={classes.smallText}>Forfatter:</p>
            <Thomas />
        </BlogPostWrapper>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "skattestyrelsen_logo_2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
